const CHResults = () => {
	return (
		<>
			<path
				d="M6.93 16.88C6.18 20.32 8.46 24.15 11.84 25.13C14.02 25.76 16.63 25.16 17.99 23.35C19.46 21.39 19.19 18.66 18.71 16.26C18.48 15.13 18.21 13.98 17.56 13.04C14.55 8.72997 7.81 12.93 6.94 16.89L6.93 16.88Z"
				fill="#F7D9D8"
			/>
			<path
				d="M26.7 10.06C26.5 10.16 26.42 10.4 26.52 10.59C27.33 12.22 27.75 14.02 27.75 15.85C27.75 22.43 22.41 27.77 15.83 27.77C9.25 27.77 3.91 22.43 3.91 15.85C3.91 9.27001 9.25 3.93001 15.84 3.93001C17.65 3.93001 19.43 4.33001 21.05 5.12001C21.26 5.18001 21.48 5.06001 21.54 4.85001C21.58 4.69001 21.53 4.52001 21.4 4.43001C19.67 3.58001 17.77 3.14001 15.84 3.14001C8.82 3.14001 3.13 8.83001 3.13 15.85C3.13 22.87 8.82 28.56 15.84 28.56C22.86 28.56 28.55 22.87 28.55 15.85C28.55 13.9 28.11 11.98 27.24 10.23C27.14 10.03 26.9 9.96001 26.71 10.05L26.7 10.06Z"
				fill="#C68486"
			/>
			<path
				d="M15.84 28.69C8.76 28.69 3 22.93 3 15.85C3 8.77001 8.76 3.01001 15.84 3.01001H15.86C17.79 3.01001 19.73 3.46001 21.46 4.31001C21.65 4.45001 21.73 4.67001 21.67 4.88001C21.63 5.01001 21.54 5.13001 21.42 5.19001C21.3 5.26001 21.16 5.27001 21.02 5.24001C19.4 4.45001 17.61 4.05001 15.84 4.05001C9.34 4.05001 4.04 9.34001 4.04 15.84C4.04 22.34 9.33 27.63 15.83 27.63C22.33 27.63 27.62 22.34 27.62 15.84C27.62 14.07 27.21 12.3 26.44 10.71L26.41 10.64C26.41 10.64 26.41 10.64 26.41 10.63V10.61C26.3 10.36 26.41 10.06 26.65 9.94001C26.91 9.81001 27.22 9.92001 27.35 10.17C28.22 11.93 28.67 13.89 28.67 15.84C28.67 22.92 22.91 28.68 15.83 28.68L15.84 28.69ZM15.85 3.26001H15.83C8.89 3.26001 3.24 8.91001 3.24 15.85C3.24 22.79 8.89 28.44 15.83 28.44C22.77 28.44 28.42 22.79 28.42 15.85C28.42 13.93 27.98 12.01 27.13 10.29C27.06 10.16 26.9 10.1 26.76 10.17C26.65 10.23 26.59 10.35 26.61 10.47H26.65V10.57C27.46 12.21 27.88 14.03 27.88 15.86C27.88 22.5 22.48 27.9 15.84 27.9C9.2 27.9 3.79 22.49 3.79 15.85C3.79 9.21001 9.19 3.81001 15.84 3.81001H15.89C17.69 3.81001 19.49 4.23001 21.11 5.01001C21.16 5.02001 21.23 5.01001 21.3 4.98001C21.36 4.94001 21.41 4.89001 21.43 4.82001C21.46 4.71001 21.43 4.60001 21.33 4.53001C19.65 3.71001 17.75 3.27001 15.86 3.27001L15.85 3.26001Z"
				fill="#C68486"
			/>
			<path
				d="M20.34 9.55001C20.46 9.37001 20.4 9.12001 20.22 9.00001C16.43 6.58001 11.39 7.68001 8.97 11.47C6.55 15.26 7.65 20.3 11.44 22.72C15.23 25.14 20.27 24.04 22.69 20.25C24.41 17.55 24.4 14.1 22.65 11.42C22.55 11.22 22.31 11.15 22.11 11.25C21.91 11.35 21.84 11.59 21.94 11.79C21.95 11.82 21.97 11.84 21.99 11.86C24.2 15.26 23.23 19.82 19.83 22.03C16.43 24.24 11.87 23.27 9.66 19.87C7.45 16.47 8.42 11.91 11.82 9.70001C14.24 8.13001 17.36 8.12001 19.79 9.67001C19.97 9.78001 20.21 9.73001 20.33 9.55001H20.34Z"
				fill="#C68486"
			/>
			<path
				d="M15.81 24.13C14.25 24.13 12.72 23.69 11.37 22.82C9.51 21.63 8.22 19.79 7.75 17.63C7.28 15.47 7.67 13.26 8.86 11.4C10.05 9.54002 11.89 8.25002 14.05 7.78002C16.21 7.30002 18.42 7.70002 20.28 8.89002C20.52 9.05002 20.59 9.37002 20.44 9.61002C20.28 9.85002 19.97 9.92002 19.73 9.77002C17.35 8.24002 14.27 8.25002 11.9 9.79002C10.28 10.84 9.17 12.46 8.77 14.35C8.37 16.24 8.73 18.17 9.78 19.79C11.95 23.13 16.43 24.08 19.78 21.91C23.12 19.74 24.07 15.26 21.9 11.91C21.88 11.89 21.86 11.86 21.85 11.83C21.79 11.71 21.77 11.57 21.82 11.43C21.87 11.29 21.95 11.19 22.08 11.12C22.34 10.99 22.65 11.09 22.78 11.34C24.54 14.05 24.56 17.57 22.81 20.3C21.62 22.16 19.78 23.45 17.62 23.92C17.02 24.05 16.42 24.12 15.83 24.12L15.81 24.13ZM15.84 7.84002C15.26 7.84002 14.68 7.90002 14.1 8.03002C12.01 8.49002 10.22 9.74002 9.07 11.54C7.92 13.34 7.53 15.49 7.99 17.58C8.45 19.67 9.7 21.46 11.5 22.61C13.3 23.76 15.45 24.15 17.54 23.69C19.63 23.23 21.42 21.98 22.57 20.18C24.27 17.53 24.25 14.12 22.53 11.48C22.49 11.4 22.43 11.36 22.36 11.33C22.29 11.31 22.22 11.31 22.15 11.35C22.02 11.42 21.96 11.58 22.03 11.72C22.03 11.74 22.05 11.75 22.06 11.77C24.31 15.24 23.33 19.88 19.87 22.12C16.41 24.36 11.77 23.38 9.53 19.92C8.44 18.25 8.07 16.25 8.49 14.29C8.91 12.34 10.06 10.66 11.73 9.57002C14.19 7.98002 17.37 7.97002 19.83 9.54002C19.95 9.62002 20.12 9.58002 20.2 9.46002C20.28 9.33002 20.24 9.17002 20.12 9.08002C18.81 8.24002 17.33 7.81002 15.81 7.81002L15.84 7.84002Z"
				fill="#C68486"
			/>
			<path
				d="M15.84 12.9C16.21 12.9 16.57 12.97 16.91 13.1C17.11 13.18 17.34 13.08 17.42 12.87C17.5 12.67 17.4 12.44 17.19 12.36C15.26 11.6 13.08 12.55 12.32 14.49C11.56 16.42 12.51 18.6 14.45 19.36C16.38 20.12 18.56 19.17 19.32 17.23C19.67 16.34 19.67 15.36 19.32 14.47C19.24 14.27 19.01 14.17 18.8 14.25C18.6 14.33 18.5 14.56 18.58 14.77C19.19 16.29 18.45 18.01 16.93 18.62C15.41 19.23 13.69 18.49 13.08 16.97C12.47 15.45 13.21 13.73 14.73 13.12C15.08 12.98 15.46 12.91 15.84 12.91V12.9Z"
				fill="#C68486"
			/>
			<path
				d="M15.82 19.73C15.35 19.73 14.87 19.64 14.41 19.46C13.45 19.08 12.69 18.35 12.27 17.4C11.85 16.45 11.83 15.4 12.21 14.43C12.59 13.47 13.32 12.7 14.27 12.29C15.22 11.88 16.27 11.85 17.24 12.23C17.51 12.33 17.64 12.64 17.54 12.91C17.49 13.04 17.39 13.14 17.26 13.2C17.13 13.26 16.99 13.26 16.86 13.2C16.53 13.07 16.19 13.01 15.84 13.01C15.48 13.02 15.11 13.08 14.77 13.21C14.07 13.49 13.51 14.03 13.22 14.73C12.92 15.43 12.91 16.2 13.2 16.9C13.48 17.6 14.02 18.16 14.72 18.45C15.42 18.74 16.19 18.76 16.89 18.47C18.34 17.89 19.05 16.23 18.47 14.78C18.42 14.65 18.42 14.51 18.47 14.38C18.52 14.25 18.63 14.15 18.76 14.1C19.03 13.99 19.33 14.12 19.44 14.39C19.81 15.31 19.81 16.32 19.44 17.24C18.84 18.77 17.37 19.7 15.82 19.7V19.73ZM15.82 12.22C15.33 12.22 14.83 12.32 14.37 12.53C13.48 12.92 12.8 13.63 12.44 14.53C12.09 15.43 12.11 16.42 12.49 17.31C12.88 18.2 13.59 18.88 14.49 19.24C16.35 19.97 18.46 19.05 19.19 17.19C19.53 16.33 19.52 15.38 19.19 14.52C19.13 14.38 18.97 14.32 18.84 14.37C18.7 14.43 18.63 14.59 18.69 14.72C19.32 16.3 18.55 18.1 16.97 18.74C16.2 19.05 15.37 19.04 14.61 18.71C13.85 18.39 13.26 17.79 12.96 17.02C12.65 16.25 12.66 15.41 12.99 14.66C13.31 13.9 13.91 13.31 14.68 13.01C15.05 12.86 15.43 12.79 15.83 12.79C15.83 12.79 15.83 12.79 15.85 12.79C16.23 12.79 16.61 12.86 16.96 13C17.03 13.03 17.1 13.03 17.17 13C17.24 12.97 17.29 12.92 17.31 12.85C17.34 12.78 17.33 12.71 17.31 12.64C17.28 12.57 17.23 12.52 17.16 12.5C16.73 12.33 16.28 12.25 15.83 12.25L15.82 12.22Z"
				fill="#C68486"
			/>
			<path
				d="M21.39 6.29997L21.6 9.52997L15.55 15.58C15.39 15.74 15.39 15.99 15.55 16.14C15.71 16.29 15.96 16.3 16.11 16.14L22.16 10.09L25.39 10.3H25.42C25.53 10.3 25.63 10.26 25.7 10.18L28.45 7.42997C28.61 7.26997 28.61 7.01997 28.45 6.86997C28.38 6.79997 28.29 6.75997 28.2 6.74997L25.27 6.54997C25.24 6.48997 25.19 6.43997 25.13 6.40997L24.93 3.47997C24.91 3.25997 24.71 3.09997 24.5 3.11997C24.41 3.11997 24.33 3.16997 24.26 3.22997L21.52 5.97997C21.44 6.05997 21.4 6.16997 21.4 6.28997L21.39 6.29997ZM25.26 9.49997L22.9 9.34997L24.9 7.34997L27.26 7.49997L25.26 9.49997ZM24.18 4.42997L24.33 6.78997L22.34 8.78997L22.19 6.42997L24.18 4.42997Z"
				fill="#C68486"
			/>
			<path
				d="M15.83 16.38C15.7 16.38 15.56 16.33 15.46 16.23C15.36 16.13 15.31 16 15.31 15.86C15.31 15.72 15.36 15.59 15.46 15.49L21.47 9.48L21.26 6.3C21.26 6.15 21.31 6 21.41 5.9L24.15 3.15C24.23 3.07 24.35 3.01 24.46 3C24.6 3 24.74 3.03 24.84 3.12C24.95 3.21 25.02 3.33 25.03 3.47L25.22 6.34C25.22 6.34 25.29 6.4 25.31 6.43L28.18 6.62C28.31 6.62 28.43 6.68 28.51 6.77C28.61 6.87 28.66 7 28.66 7.14C28.66 7.28 28.61 7.41 28.51 7.51L25.76 10.26C25.66 10.36 25.53 10.41 25.39 10.41H25.36L22.18 10.2L16.17 16.21C16.07 16.31 15.93 16.36 15.8 16.36L15.83 16.38ZM21.51 6.29L21.72 9.58L15.63 15.67C15.58 15.72 15.55 15.79 15.55 15.86C15.55 15.93 15.58 16 15.63 16.05C15.74 16.16 15.91 16.16 16.02 16.05L22.11 9.96L25.4 10.17C25.49 10.17 25.56 10.14 25.61 10.09L28.36 7.34C28.47 7.23 28.47 7.06 28.36 6.95C28.31 6.9 28.25 6.88 28.19 6.87L25.19 6.67L25.16 6.61C25.16 6.61 25.11 6.54 25.07 6.52L25.01 6.49L24.81 3.49C24.79 3.34 24.67 3.23 24.51 3.25C24.45 3.25 24.39 3.28 24.35 3.33L21.61 6.08C21.55 6.14 21.53 6.21 21.53 6.29H21.51ZM25.31 9.62L22.62 9.44L24.85 7.21L27.54 7.39L25.31 9.62ZM23.19 9.23L25.21 9.36L26.97 7.6L24.95 7.47L23.19 9.23ZM22.24 9.07L22.07 6.38L24.29 4.15L24.47 6.84L22.24 9.07ZM22.32 6.47L22.45 8.49L24.21 6.73L24.08 4.71L22.33 6.47H22.32Z"
				fill="#C68486"
			/>
		</>
	);
};

export default CHResults;
